/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import QueryString from 'qs';
import { useLocation } from 'react-router';

const useIsPartner = () => {
    const location = useLocation();
    const urlParams = QueryString.parse(location.search.slice(1));
    if (urlParams?.utm_partnerships) {
        if (typeof sessionStorage !== 'undefined' && urlParams) {
            sessionStorage.setItem('Partnership', JSON.stringify(urlParams));
        }
        return true;
    }

    if (typeof window !== 'undefined' && window?.sessionStorage) {
        const sessionStored = sessionStorage.getItem('Partnership');
        const sessionParams = JSON.parse(sessionStored);
        return !!sessionParams?.utm_partnerships;
    }
    return false;
};

export default useIsPartner;
