/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { object, string } from 'prop-types';
import Media from 'react-media';
import { useSelector } from 'react-redux';
import { getSSRDeviceType } from '../../../../state/ducks/App/App-Selectors';

const styles = () => ({
    container: {
        display: 'flex',
        background: '#f4f0f6',
        '@media only screen and (min-width: 600.5px) ': {
            width: '100%',
            flexWrap: 'wrap',
            boxSizing: 'border-box',
            padding: '5px',
        },
    },
    eyebrowBanner: {
        display: 'block',
        margin: '0 auto',
        padding: '5px',
    },
    eyebrowBannerMobile: {
        display: 'block',
        margin: '1em auto',
    },
});

const PartnershipEyebrow = ({ classes, data, logoClick }) => {
    const ssrDeviceType = useSelector(getSSRDeviceType);

    if (data) {
        return (
            <Link to={logoClick} className={classes.container}>
                <Media
                    queries={{
                        small: '(max-width: 600px)',
                        medium: '(min-width: 601px) and (max-width: 1024px)',
                        large: '(min-width: 1025px)',
                    }}
                    defaultMatches={{
                        desktop: ssrDeviceType === 'desktop',
                        tablet: ssrDeviceType === 'tablet',
                        mobile: ssrDeviceType === 'mobile',
                    }}
                >
                    {(matches) => (
                        <>
                            {(matches.large && <img alt="eyebrowBanner" src={`${data?.images?.eyebrow.url}`}  className={classes.eyebrowBanner} />)}
                            {(matches.medium && <img alt="eyebrowBanner" src={`${data?.images?.eyebrow.url}`}  className={classes.eyebrowBanner} />)}
                            {(matches.small && <img alt="eyebrowBanner" src={`${data?.images?.mobile_eyebrow?.url || data?.images?.eyebrow.url}`}  className={classes.eyebrowBannerMobile} />)}
                        </>
                    )}
                </Media>
            </Link>
        );
    }
    return null;
};

PartnershipEyebrow.propTypes = {
    classes: object.isRequired,
    data: object.isRequired,
    logoClick: string,
};

PartnershipEyebrow.defaultProps = {
    logoClick: '/',
};
export default withStyles(styles)(PartnershipEyebrow);
