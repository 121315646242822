/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useQuery, gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import mbpLogger from 'mbp-logger';
import QueryString from 'qs';
import { useLocation } from 'react-router';
import { getBrand } from '../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { GRAPHQL_ENV } from '../../gql';
import useIsPartner from './usePartnership';

const usePartnershipQuery = () => {
    const brand = useSelector(getBrand);
    const location = useLocation();
    const urlParams = QueryString.parse(location.search.slice(1));
    const isPartnership = useIsPartner();
    const CONTENT_QUERY = gql`{
            globalPartnerData: findContent(brand : "global", environment: "${GRAPHQL_ENV}", contentType:"partnerships", query:"\\"utm_partnership\\": \\"${urlParams?.utm_partnerships}\\"")
        {
          content
        }
            brandPartnerData: findContent(brand : "${brand['domain-name']}", environment: "${GRAPHQL_ENV}", locale: "en-us", contentType:"partnerships",  query:"\\"utm_partnership\\": \\"${urlParams?.utm_partnerships}\\"")
        {
          content
        }
    }
    `;
    const { loading, error, data } = useQuery(CONTENT_QUERY, {
        skip: !isPartnership,
    });
    // Check for error
    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            query: CONTENT_QUERY,
            component: 'usePartnershipQuery.js',
            message: 'Error loading data from Graphql',
            env: GRAPHQL_ENV,
            error,
        });
    }

    return { data, loading, error };
};
export default usePartnershipQuery;
