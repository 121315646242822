/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useState } from 'react';
import {
    makeStyles,
} from '@material-ui/styles';
import { func, string, object } from 'prop-types';
import IconClose from '@material-ui/icons/Close';

const styles = () => ({
    bannerContainer: {
        display: 'flex',
        backgroundColor: 'rgb(244, 242, 247)',
        position: 'fixed',
        bottom: '0',
        zIndex: 999,
        width: '100%',
    },
    bannerText: {
        fontSize: '12px',
        margin: '0',
    },
    celebrationsImage: {
        width: '64px',
        height: '64px',
    },
    flowersImage: {
        width: '70px',
        height: '70px',
    },
    imageContainer: {
        marginTop: '15px',
        marginLeft: '10px',
        paddingRight: '10px',
    },
    buttonContainer: {
        marginLeft: '90px',
        marginTop: '30px',
    },
    closeButton: {
        marginTop: '35px',
        paddingLeft: '10px',
        paddingRight: '0px',
        border: 'none',
        backgroundColor: 'rgb(244, 242, 247)',
    },
    title: {
        marginTop: '20px',
        marginBottom: '0',
    },
    viewButton: {
        border: 'none',
        color: 'blue',
        backgroundColor: 'rgb(244, 242, 247)',
        fontSize: '18px',
    },
});

const useStyles = makeStyles(styles);

const NativeAppInstallBanner = (props) => {
    const { handleCloseBanner, presentationFamily, brand } = props;
    const classes = useStyles();
    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);

    useEffect(() => {
        const handler = (e) => {
            e.preventDefault();
            setSupportsPWA(true);
            setPromptInstall(e);
        };
        window.addEventListener('beforeinstallprompt', handler);

        return () => window.removeEventListener('transitionend', handler);
    }, []);

    const smartlinkUrl = () => {
        if (brand.domain === '1800flowers') {
            window.location.href = 'https://smart.link/sotoslxaxjnkc';
        }

        if (presentationFamily === 'food') {
            window.location.href = 'https://smart.link/w2tudqta8mb6f';
        }
    };

    const onClick = (evt) => {
        evt.preventDefault();
        smartlinkUrl();
        if (!promptInstall) {
            return;
        }
        promptInstall.prompt();
    };

    return (
        <>
            {supportsPWA && (
                <div className={classes.bannerContainer}>
                    <IconClose
                        className={classes.closeButton}
                        onClick={handleCloseBanner}
                    />
                    <div className={classes.imageContainer}>
                        {presentationFamily === 'food' && (
                            <img src="https://play-lh.googleusercontent.com/4_YC57Wth4Bgusi3gNMwRU6pRwbvW4026aZDaaezV58SmEbqfDOPSyeg4-2lIDmPG0k=s100" alt="passportIcon" className={classes.celebrationsImage} />
                        )}
                        {brand.domain === '1800flowers' && (
                            <img src="https://play-lh.googleusercontent.com/eiD9tTc2b-OZoZxulQjap3Jp4Mm2sWXQD_pQfL8eXIzaGj0FU4O48NuXmBG0KxTBipRD=s360-rw" alt="1800flowersIcon" className={classes.flowersImage} />
                        )}
                    </div>
                    <div>
                        {presentationFamily === 'food' && <h5 className={classes.title}>Celebrations Passport</h5>}
                        {brand.domain === '1800flowers' && <h5 className={classes.title}>1800 Flowers</h5>}
                        <p className={classes.bannerText}>1800 Flowers</p>
                        <p className={classes.bannerText}>GET - On Google Play Store</p>
                    </div>
                    <span className={classes.buttonContainer}>
                        <button
                            type="button"
                            className={classes.viewButton}
                            id="setup_button"
                            aria-label="Install app"
                            title="Install app"
                            onClick={onClick}
                        >
                            View
                        </button>
                    </span>
                </div>
            )}

        </>
    );
};

NativeAppInstallBanner.propTypes = {
    handleCloseBanner: func.isRequired,
    presentationFamily: string.isRequired,
    brand: object.isRequired,
};

export default NativeAppInstallBanner;
