/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
// libraries
import React, { useState, memo, useEffect } from 'react';
import {
    object, string, bool, array,
} from 'prop-types';
import { useSelector, connect, useDispatch } from 'react-redux';
import { useLocation, withRouter } from 'react-router';
import loadable from '@loadable/component';
// helpers, redux, gql
import Media from 'react-media';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Helmet } from 'react-helmet';
import { selectors } from '../../../../state/ducks/App';
import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';

import NativeAppInstallBanner from './NativeAppInstallBanner/NativeAppInstallBanner';
import { getActiveABTest } from '../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

// header ui components
const DesktopGraphqlHeader = loadable(() => import(/* webpackChunkName: "DesktopGraphqlHeader" */ './DesktopGraphqlHeader'));
const MobileHeaderContainer = loadable(() => import(/* webpackChunkName: "DesktopGraphqlHeader" */ './MobileHeaderContainer'));
const MobileGraphqlHeader = loadable(() => import(/* webpackChunkName: "MobileGraphqlHeader" */ './MobileGraphqlHeader'));

const TABLET_WIDTH_PORTRAIT = 767;
const TABLET_WIDTH_LANDSCAPE = 1025;
const FOOD_MOBILE_QUERY = `(max-width: ${TABLET_WIDTH_PORTRAIT - 1}px)`;
const FOOD_DESK_QUERY = `(min-width: ${TABLET_WIDTH_PORTRAIT}px)`;
const FLOWER_MOBILE_QUERY = `(max-width: ${TABLET_WIDTH_LANDSCAPE}px)`;
const FLOWER_DESK_QUERY = `(min-width: ${TABLET_WIDTH_LANDSCAPE}px)`;

const GraphqlHeader = memo((props) => {
    const {
        brand,
        presentationFamily,
        deviceType,
        ffWhichCheckoutVariation,
        abTestCheckoutUniversal,
        ffIsSmartAppBannerEnabled,
        brandPartnerData,
        globalPartnerData,
    } = props;
    const location = useLocation();
    if (location?.pathname?.indexOf('/auth/') >= 0) {
        return null;
    }

    const dispatch = useDispatch();
    useEffect(() => {
        if (globalPartnerData?.title) {
            dispatch(trackEvent({
                eventCategory: 'Partnership',
                eventLabel: globalPartnerData?.title,
                eventAction: 'Visit',
            }));
        }
    }, [globalPartnerData?.title]);

    const isTabWidth = useMediaQuery('(max-width: 1025px)');
    const showBanner = useMediaQuery('(max-width: 600px');
    const mobileHeaderOnTab = presentationFamily === 'food';

    const [closeBanner, setCloseBanner] = useState(false);
    const countryName = useSelector(selectors.country);

    const normalizeDeviceTypeByPresentationFamily = () => {
        if (deviceType === 'tablet' && presentationFamily === 'flower') return 'mobile'; // The flower brands request the mobile header for tablet
        if (deviceType === 'tablet' && presentationFamily === 'food') return 'desktop'; // The food brands require the desktop header for tablet
        return deviceType;
    };

    const device = normalizeDeviceTypeByPresentationFamily();

    const isInCheckout =        (
        location?.pathname?.indexOf('/checkout/') >= 0
            && ['flower', 'food'].includes(ffWhichCheckoutVariation)
            && abTestCheckoutUniversal !== 'universal'
    )
        || (
            (['enterprise', 'enterprise2'].includes(ffWhichCheckoutVariation) || abTestCheckoutUniversal === 'universal')
            && location?.pathname?.indexOf('/checkout/') >= 0
            && location?.pathname?.indexOf('/checkout/cart') < 0
            && device === 'desktop'
        );

    const countryNameToLowerCase = countryName?.toLowerCase();
    const isEyeBrowShow = countryNameToLowerCase === '' || countryNameToLowerCase === 'united states';

    const handleCloseBanner = () => {
        setCloseBanner(true);
    };

    if ((mobileHeaderOnTab || presentationFamily === 'flower') && isTabWidth) {
        return (
            <>
                {
                    ffIsSmartAppBannerEnabled && !closeBanner && (presentationFamily === 'food' || brand.domain === '1800flowers') && showBanner && <NativeAppInstallBanner presentationFamily={presentationFamily} brand={brand} handleCloseBanner={handleCloseBanner} />
                }
                <MobileHeaderContainer
                    isEyeBrowShow={isEyeBrowShow}
                />
            </>
        );
    }

    const partnerUIOverride = (arr) => {
        if (!arr?.length) return <></>;
        const navEls = [];
        arr.forEach((navEl) => { navEls.push(`[data-displayname*="${navEl}"]`); });
        const styleChunk = `[data-component="renderLogoRow"]{height:fit-content!important}[data-component="brandTabs"],nav .mainLinkList li:is(${navEls.join(',')}){display:none !important}`;
        return <style>{styleChunk}</style>;
    };

    const hideAllNavigation = () => {
        const hideAllNavChunk = '[data-component="renderLogoRow"]{height:fit-content!important}[data-component="navigationMenuV2"],[aria-label="Secondary Navigation"]{display:none!important}';
        return <style>{hideAllNavChunk}</style>;
    };

    return (
        <>
            {(brandPartnerData?.ui?.navigation_exposure?.link_titles?.length || brandPartnerData?.ui?.navigation_exposure?.hide_all) && <Helmet>{ brandPartnerData?.ui?.navigation_exposure?.hide_all ? hideAllNavigation() || '' : partnerUIOverride(brandPartnerData?.ui?.navigation_exposure?.link_titles) || ''}</Helmet>}
            {isInCheckout
                ? (
                    <Media
                        query={FLOWER_MOBILE_QUERY}
                        defaultMatches={device === 'tablet'}
                        render={() => (
                            <MobileGraphqlHeader
                                isEyeBrowShow={isEyeBrowShow}
                                brand={brand}
                                presentationFamily={presentationFamily}
                            />
                        )}
                    />
                ) : (
                    <>
                        <Media
                            query={presentationFamily === 'flower' ? FLOWER_MOBILE_QUERY : FOOD_MOBILE_QUERY}
                            defaultMatches={device === 'mobile'}
                            render={() => (
                                <>
                                    {
                                        ffIsSmartAppBannerEnabled && !closeBanner && (presentationFamily === 'food' || brand.domain === '1800flowers') && showBanner && <NativeAppInstallBanner presentationFamily={presentationFamily} brand={brand} handleCloseBanner={handleCloseBanner} />
                                    }
                                    <MobileHeaderContainer
                                        isEyeBrowShow={isEyeBrowShow}
                                    />
                                </>
                            )}
                        />
                        <Media
                            query={presentationFamily === 'flower' ? FLOWER_DESK_QUERY : FOOD_DESK_QUERY}
                            defaultMatches={device === 'desktop'}
                            render={() => (
                                <DesktopGraphqlHeader
                                    isEyeBrowShow={isEyeBrowShow}
                                    brand={brand}
                                    presentationFamily={presentationFamily}
                                    globalPartnerData={globalPartnerData}
                                />
                            )}
                        />
                    </>
                )}
        </>

    );
});

GraphqlHeader.propTypes = {
    brand: object.isRequired,
    presentationFamily: string.isRequired,
    deviceType: string.isRequired,
    ffWhichCheckoutVariation: string.isRequired,
    abTestCheckoutUniversal: string.isRequired,
    ffIsSmartAppBannerEnabled: bool.isRequired,
    brandPartnerData: array,
    globalPartnerData: array,
};
GraphqlHeader.defaultProps = {
    brandPartnerData: [],
    globalPartnerData: [],
};
const mapStateToProps = (state) => ({
    ffIsSmartAppBannerEnabled: getFeatureFlag('is-smart-app-banner-enabled')(state),
    abTestCheckoutUniversal: getActiveABTest('checkout')(state),
});

export default withRouter(
    connect(
        mapStateToProps,
        null,
    )(GraphqlHeader),
);
